( function ( $ ) {
	
	function distanceBetweenElems( elem1, elem2 ) {
		var e1Rect = elem1.getBoundingClientRect();
		var e2Rect = elem2.getBoundingClientRect();
		var dx = ( e1Rect.left + ( e1Rect.right-e1Rect.left ) / 2 ) - ( e2Rect.left + ( e2Rect.right - e2Rect.left ) / 2 );
		var dy = ( e1Rect.top + ( e1Rect.bottom-e1Rect.top ) / 2 ) - ( e2Rect.top + ( e2Rect.bottom - e2Rect.top ) / 2 );
		var dist = Math.sqrt( dx * dx + dy * dy );
		return Math.ceil( dist );
	}
	
	$( document ).ready( function() {
		
		var line_counter = 1;
		var dot_animation_class = 'wow bounceIn';
		var time_between_dots = 0.03;
		
		function straight_line( section ) {
			
			var section = $( '#section-' + section );
			var line = $( '<div/>', { 'id': 'line-' + line_counter, 'class': 'destroyable line vertical h-center' } );
			var anim_delay = 0.5;
			
			for ( i = 0; i < 12; i++ ) {
				line.append( $( '<div/>', { 'class': 'dot ' + dot_animation_class } ).css( 'animationDelay', anim_delay + 's' ) );
				anim_delay+= time_between_dots;
			}
			
			line_counter++;
			section.append( line );
			
		}
		
		function section_2_line() {
			
			var section = $( '#section-2' );
			var anim_delay = 0.5, temp_delay = 0;
			
			var linespace = distanceBetweenElems( $( '#section-2 .par-icons .wrap:eq(0)' ).get( 0 ), $( '#section-2 .par-icons .wrap:eq(1)' ).get( 0 ) ) - 6;
			
			var line;
			var first = line_counter, last;
			var container_1 = $( '<div/>', { 'class': 'line-h-wrap' } );
			for ( i = 0; i < 4; i++ ) {
				temp_delay = anim_delay;
				
				line = $( '<div/>', { 'id': 'line-' + line_counter, 'class': 'destroyable line vertical' } );
				for ( k = 0; k < 10; k++ ) {
					line.append( $( '<div/>', { 'class': 'dot ' + dot_animation_class } ).css( 'animationDelay', temp_delay + 's' ) );
					temp_delay+= time_between_dots;
				}
				
				if ( 3 !== i ) {
					line.css( 'marginRight', linespace );
				} else {
					anim_delay = temp_delay;
				}
				
				last = line_counter;
				line_counter++;
				container_1.append( line );
				
			}
			section.append( container_1 );
			
			var hwidth = distanceBetweenElems( $( '#line-' + first ).get( 0 ), $( '#line-' + last ).get( 0 ) );
			var numdots = Math.floor( ( 6 + hwidth ) / 12 );
			
			line = $( '<div/>', { 'id': 'line-' + line_counter, 'class': 'destroyable line horizontal h-center mtop mbottom' } );
			for ( i = 0; i < numdots; i++ ) {
				line.append( $( '<div/>', { 'class': 'dot ' + dot_animation_class } ).css( 'animationDelay', anim_delay + 's' ) );
			}
			line_counter++;
			anim_delay+= time_between_dots;
			section.append( line );
			
			line = $( '<div/>', { 'id': 'line-' + line_counter, 'class': 'destroyable line vertical h-center' } );
			for ( i = 0; i < 12; i++ ) {
				line.append( $( '<div/>', { 'class': 'dot ' + dot_animation_class } ).css( 'animationDelay', anim_delay + 's' ) );
				anim_delay+= time_between_dots;
			}
			line_counter++;
			section.append( line );
			
		}
		
		function section_3_line() {
			
			var section = $( '#section-3' );
			var anim_delay = 0.5, temp_delay = 0;
			
			var line = $( '<div/>', { 'id': 'line-' + line_counter, 'class': 'destroyable line vertical h-center' } );
			
			for ( i = 0; i < 12; i++ ) {
				line.append( $( '<div/>', { 'class': 'dot ' + dot_animation_class } ).css( 'animationDelay', anim_delay + 's' ) );
				anim_delay+= time_between_dots;
			}
			
			line_counter++;
			section.append( line );
			
			var hwidth = distanceBetweenElems( $( '#section-4 .half:first' ).get( 0 ), $( '#section-4 .half:last' ).get( 0 ) );
			var numdots = Math.ceil( ( 6 + hwidth ) / 12 );
			
			line = $( '<div/>', { 'id': 'line-' + line_counter, 'class': 'destroyable line horizontal h-center mtop mbottom' } );
			for ( i = 0; i < numdots; i++ ) {
				line.append( $( '<div/>', { 'class': 'dot ' + dot_animation_class } ).css( 'animationDelay', anim_delay + 's' ) );
			}
			line_counter++;
			anim_delay+= time_between_dots;
			section.append( line );
			
			var line;
			var container_1 = $( '<div/>', { 'class': 'line-h-wrap' } );
			for ( i = 0; i < 2; i++ ) {
				temp_delay = anim_delay;
				
				line = $( '<div/>', { 'id': 'line-' + line_counter, 'class': 'destroyable line vertical' } );
				for ( k = 0; k < 8; k++ ) {
					line.append( $( '<div/>', { 'class': 'dot ' + dot_animation_class } ).css( 'animationDelay', temp_delay + 's' ) );
					temp_delay+= time_between_dots;
				}
				
				if ( 0 === i ) {
					line.css( 'marginRight', hwidth - 3 );
				}
				
				if ( 1 === i ) {
					anim_delay = temp_delay;
				}
				
				line_counter++;
				container_1.append( line );
				
			}
			section.append( container_1 );
			
		}
		
		function section_4_line() {
			
			var section = $( '#section-4' );
			var anim_delay = 0.5, temp_delay = 0;
			
			var hwidth = distanceBetweenElems( $( '#section-4 .half:first' ).get( 0 ), $( '#section-4 .half:last' ).get( 0 ) );
			var numdots = Math.ceil( ( 6 + hwidth ) / 12 );
			
			var line;
			var container_1 = $( '<div/>', { 'class': 'line-h-wrap' } );
			for ( i = 0; i < 2; i++ ) {
				temp_delay = anim_delay;
				
				line = $( '<div/>', { 'id': 'line-' + line_counter, 'class': 'destroyable line vertical' } );
				for ( k = 0; k < 10; k++ ) {
					line.append( $( '<div/>', { 'class': 'dot ' + dot_animation_class } ).css( 'animationDelay', temp_delay + 's' ) );
					temp_delay+= time_between_dots;
				}
				
				if ( 0 === i ) {
					line.css( 'marginRight', hwidth - 3 );
				}
				
				if ( 1 === i ) {
					anim_delay = temp_delay;
				}
				
				line_counter++;
				container_1.append( line );
				
			}
			section.append( container_1 );
			
			line = $( '<div/>', { 'id': 'line-' + line_counter, 'class': 'destroyable line horizontal h-center mtop mbottom' } );
			for ( i = 0; i < numdots; i++ ) {
				line.append( $( '<div/>', { 'class': 'dot ' + dot_animation_class } ).css( 'animationDelay', anim_delay + 's' ) );
			}
			line_counter++;
			anim_delay+= time_between_dots;
			section.append( line );
			
			var line = $( '<div/>', { 'id': 'line-' + line_counter, 'class': 'destroyable line vertical h-center' } );
			
			for ( i = 0; i < 12; i++ ) {
				line.append( $( '<div/>', { 'class': 'dot ' + dot_animation_class } ).css( 'animationDelay', anim_delay + 's' ) );
				anim_delay+= time_between_dots;
			}
			
			line_counter++;
			section.append( line );
			
		}
		
		function animation_init() {
			
			if ( $( window ).width() >= 640 ) {
				
				straight_line( 1 );
				section_2_line();
				section_3_line();
				section_4_line();
				
			} else {
				
				straight_line( 1 );
				straight_line( 2 );
				straight_line( 3 );
				straight_line( 4 );
				
			}
			
		}
		
		function animation_stop() {
			
			$( '.destroyable' ).remove();
			
		}
		
		if ( $( 'body' ).hasClass( 'page-id-190' ) ) {
			
			animation_init();
			
			$( window ).resize( function() {
				animation_stop();
				animation_init();
			} );
			
		}
		
	} );

} )( jQuery );
