( function ( $ ) {
	
	$( document ).ready( function() {

		$('.isotope').each(function() {
			/* To use isotope: <div class="isotope" data-itemselector=".box" data-colwidth=".col-width" data-filters="#tope1-filters" data-filters-reset="#tope1-reset"> */

			var tope = $(this);
			var filters = tope.data('filters');
			var filtersReset = tope.data('filters-reset');
			var doingFiltering = false;

			if (tope.length) {

				if ('undefined' !== typeof filters && filters.length) {
					filters = $(filters);
				}

				tope.isotope({
					itemSelector: tope.data('itemselector'),
					layoutMode: 'masonry'
				});

				if ('undefined' !== typeof filtersReset && filtersReset.length) {
					var topeReset = $(filtersReset);
					topeReset.click( function() {

						if (doingFiltering) {
							return false;
						}
						doingFiltering = true;

						tope.isotope({filter: '*'});
						filters.removeAttr('disabled');
						$('body, html').scroll();
						doingFiltering = false;

						filters.each(function() {
							var filter = $(this);
						});

						return false;
					} );
				}

				if ('undefined' !== typeof filters && filters.length) {
					var topeFilters = $(filters);

					topeFilters.change(function() {

						if (doingFiltering) {
							return false;
						}
						doingFiltering = true;

						filters.attr('disabled', 'disabled');

						var filterString = '';

						var filter = $(this);
						var taxonomy = filter.data('tax');
						var term = filter.val();

						if (!taxonomy || !term || '...' === term) {
							return;
						}

						filterString+= '.' + taxonomy + '-' + term;

						if (filterString.length) {
							tope.isotope({filter: filterString});
						} else {
							tope.isotope({filter: '*'});
						}

						filters.removeAttr('disabled');

						$('body, html').scroll();
						doingFiltering = false;

						return false;
					});

				}
			}
		});
		
		$( '.isotope .gallery-item img' ).each( function() {
			
			ImagesLoaded( this, function() {
				console.log( 'Isotope image loaded.' );
				$( '.isotope' ).isotope( 'layout' );
			} );
			
		} );
		
	} );

} )( jQuery );
