( function ( $ ) {
	
	function wrap_sizer() {
		
		var header_height = $( '#site-header:first' ).outerHeight();
		
		$( '.wrap.page-container:first' ).css( 'paddingTop', header_height ).css( 'marginTop', -header_height );
		
	}
	
	function footer_pad() {
		
		var footer_height = $( '.site-footer #footer-control:first' ).outerHeight();
		
		if ( !footer_height ) {
			return;
		}
		
		$( '.wrap.page-container:first' ).css( 'paddingBottom', footer_height );
		
	}
	
	$( document ).ready( function() {
		
		footer_pad();
		wrap_sizer();
		
		$( '#footer-control' ).click( function() {
			
			var control = $( this );
			var footer_elem = $( '#footer-inner' );
			var footer_wrapper = $( '#footer-wrapper' );
			
			if ( footer_wrapper.hasClass( 'open' ) ) {
				
				footer_wrapper.removeClass( 'open' );
				
			} else {
				
				footer_wrapper.addClass( 'open' );
				
			}
			
		} );
		
	} );
	
	$( window ).resize( function() {
		
		footer_pad();
		wrap_sizer();
		
	} );
	
} )( jQuery );
