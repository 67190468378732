( function ( $ ) {
	
	$( document ).ready( function() {
		
		$( document ).foundation();
		
		new WOW( {
			offset: $( window ).height() / 7
		} ).init();		

	} );
	
} )( jQuery );
