( function ( $ ) {
	
	$( document ).ready( function() {
		
		$( '[data-access="toggle"]' ).click( function( e ) {
			
			var controls = $( '#access-controls' );
			
			var is_open = controls.data( 'access' );
			is_open = ( 'open' == is_open ) ? true : false;
			
			if ( is_open ) {
				$( e.currentTarget ).toggleClass( 'isopen' );
				controls.stop().data( 'access', 'closed' ).slideUp( 'fast' );
			} else {
				$( e.currentTarget ).toggleClass( 'isopen' );
				controls.stop().data( 'access', 'open' ).slideDown( 'fast' );
			}
			
			return false;
			
		} );
		
	} );

} )( jQuery );