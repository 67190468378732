( function ( $ ) {
	
	$( document ).ready( function() {

		$( '.filter-dropdowns legend.clickable' ).click( function() {
			
			var legend = $( this );
			var container = legend.parent( '.filter-dropdowns:first' );
			var slideable = container.children( '.filters-inner:first' );
			var is_expanded = container.hasClass( 'expanded' );
			
			if ( is_expanded ) {
				slideable.slideUp( 'fast', function() {
					container.removeClass( 'expanded' );
				} );
			} else {
				container.addClass( 'expanded' );
				slideable.slideDown( 'fast' );
			}
			
		} );
		
		$( '.filter-dropdowns select.filter-dropdown' ).change( function() {
			
			var dropdown = $( this );
			
			if ( !dropdown.val() ) {
				return;
			}
			
			window.location.href = dropdown.val();
			
		} );
		
	} );
	
} )( jQuery );