( function ( $ ) {
	
	$( document ).ready( function() {
		
		var win = $( window );
		var win_width;
		var win_height;
		
		var sprite_symbol_width = 70;
		var sprite_symbol_height = 60;
		
		var maximum_animated_symbols = 10;
		var symbol_animation_time = 11000;
		var max_symbol_start_delay = 9000;
		var symbol_animation_time_drift = 5500;
		var symbol_easing = 'easeInOutSine';
		var symbol_horizontal_drift_percent = 85;
		var symbol_zindex = 999999999;
		var symbol_horizontal_origin_drift = 75;
		
		var anim_container;
		var selected_symbols = new Array();
		var max_symbol_horizontal_drift;
		var symbol_horizontal_origin;
		
		function random_sprite_coords() {
			
			var horizontal = Math.floor( Math.random() * 29 ) * sprite_symbol_width;
			var vertical = Math.floor( Math.random() * 27 ) * sprite_symbol_height;
			
			return {
				'h' : horizontal,
				'v' : vertical
			}
			
		}
		
		function animation_init() {
			
			win_width = win.width();
			win_height = win.height();
			
			if ( win_width < 640 ) {
				return;
			}
			
			max_symbol_horizontal_drift = ( ( win_width / 100 ) * symbol_horizontal_drift_percent ) / 2;
			symbol_horizontal_origin = Math.floor( ( win_width / 2 ) - ( sprite_symbol_width / 2 ) );
			
			anim_container = $( '#put-animations' );
			if ( !anim_container.length ) { return; }
			
			anim_container.addClass( 'animations' ).css( 'position', 'fixed' ).css( 'top', 0 ).css( 'left', 0 ).css( 'width', '100vw' ).css( 'height', '100vh' );
			
			create_symbols();
			begin_symbol_animation();
			
		}
		
		function create_symbols() {
			
			var symbol, start_h_drift, start_h;
			for ( i=0; i < maximum_animated_symbols; i++ ) {
				
				symbol = random_sprite_coords();
				
				var start_h_drift = Math.floor( Math.random() * symbol_horizontal_origin_drift ) + 1;
				start_h_drift = ( symbol_horizontal_origin / 100 ) * start_h_drift;
			
				start_h = ( Math.random() < .5 ) ? symbol_horizontal_origin - start_h_drift : symbol_horizontal_origin + start_h_drift;
				
				symbol = $( '<div/>', { 'class': 'symbol' } ).data( 'horizontal', symbol[ 'h' ] ).data( 'vertical', symbol[ 'v' ] ).css( 'width', sprite_symbol_width + 'px' ).css( 'height', sprite_symbol_height + 'px' ).css( 'backgroundImage', 'url("' + animations_init.img_dir + 'all-icons.png")' ).css( 'backgroundPosition', '-' + symbol[ 'h' ] + 'px ' + '-' + symbol[ 'v' ] + 'px' ).css( 'position', 'absolute' ).css( 'bottom', -Math.abs( sprite_symbol_height ) + 'px' ).css( 'left', start_h + 'px' ).css( 'opacity', '0.5' ).css( 'transform', 'scale(0.3)' ).css( 'zIndex', symbol_zindex ).appendTo( anim_container );
				
				selected_symbols.push( symbol );
				
			}
			
		}
		
		function begin_symbol_animation() {
			
			if ( !selected_symbols.length ) {
				return;
			}
			
			var random_drift, drifted_animation_time;
			for ( i=0; i < selected_symbols.length; i++ ) {
				animate_symbol( selected_symbols[ i ] );
			}
			
		}
		
		function animate_symbol( symbol ) {
			
			var start_delay = Math.floor( ( Math.random() * max_symbol_start_delay ) + 1 );
			
			var animation_time_drift = Math.floor( ( Math.random() * symbol_animation_time_drift ) + 1 );
			var animation_time = ( Math.random() < .5 ) ? symbol_animation_time + animation_time_drift : symbol_animation_time - animation_time_drift;
			
			var horizontal_drift = Math.floor( Math.random() * max_symbol_horizontal_drift );
			horizontal_drift = ( Math.random() < .5 ) ? symbol_horizontal_origin - horizontal_drift : symbol_horizontal_origin + horizontal_drift;
			
			symbol.delay( start_delay ).animate( { bottom: win_height + sprite_symbol_height, left: horizontal_drift, opacity: 1, transform: 'scale(1)' }, animation_time, symbol_easing, function() {
				
				recycle_symbol( symbol );
				alternate_symbol( symbol );
				animate_symbol( symbol );
				
			} );
			
		}
		
		function recycle_symbol( symbol ) {
			
			var start_h_drift = Math.floor( Math.random() * symbol_horizontal_origin_drift ) + 1;
			start_h_drift = ( symbol_horizontal_origin / 100 ) * start_h_drift;
			
			var start_h = ( Math.random() < .5 ) ? symbol_horizontal_origin - start_h_drift : symbol_horizontal_origin + start_h_drift;
			
			symbol.css( 'bottom', -Math.abs( sprite_symbol_height ) + 'px' ).css( 'left', start_h + 'px' ).css( 'opacity', '0.5' ).css( 'transform', 'scale(0.2)' );
			
		}
		
		function alternate_symbol( symbol ) {
			
			var new_position = random_sprite_coords();
			symbol.css( 'backgroundPosition', '-' + new_position[ 'h' ] + 'px ' + '-' + new_position[ 'v' ] + 'px' );
			
		}
		
		function animation_stop() {
			
			if ( !anim_container ) {
				return;
			}
			
			var removed = 0;
			var num_symbols = selected_symbols.length;
			if ( num_symbols ) {
				
				for ( i=0; i < num_symbols; i++ ) {
					selected_symbols[ i ].stop().fadeOut( 'fast', function() {
						
						$( this ).remove();
						removed++;
						
						if ( removed >= num_symbols ) {
							selected_symbols = [];
						}
						
					} );
				}
				
			}
			
		}
		
		if ( $( '#put-animations' ).length ) {
			
			if ( $( '#put-animations' ).hasClass( 'waypoint' ) ) {
				
				$( 'div#put-animations-trigger' ).waypoint( function() {
					
					if ( selected_symbols.length === 0 ) {
						animation_init();
					}
					
				}, { offset: '110%' } );
				
				$( 'div#put-animations-trigger' ).waypoint( function() {
					
					animation_stop();
					
				}, { offset: '250%' } );
				
			} else {
				
				animation_init();
				
			}
			
			win.resize( function() {
				animation_stop();
				animation_init();
			} );
			
		}
		
	} );

} )( jQuery );
