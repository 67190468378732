( function ( $ ) {
	
	$( document ).ready( function() {
		
		/* Big image gallery. */
		
		var open_large_image_after_element = '#smooth-state-container';
		
		var ajax_space = $( '<div/>', { id: 'ajax-space', class: 'big-image' } ).insertAfter( open_large_image_after_element );
		var slide_left = $( '<div/>', { id: 'slide-left', class: 'slide left' } ).append( $( '<i class="fa fa-angle-left" aria-hidden="true"></i>' ) ).appendTo( ajax_space ).click( function() { slideBigImage( false ); } );
		var slide_right = $( '<div/>', { id: 'slide-right', class: 'slide right' } ).append( $( '<i class="fa fa-angle-right" aria-hidden="true"></i>' ) ).appendTo( ajax_space ).click( function() { slideBigImage( true ); } );
		var slide_close = $( '<div/>', { id: 'slide-close', class: 'closeit' } ).append( $( '<i class="fa fa-times" aria-hidden="true"></i>' ) ).appendTo( ajax_space ).click( function() { closeBigImage(); } );
		
		ajax_space.on( 'swipeleft', function( the_event ) {
			slideBigImage( false );
		} ).on( 'swiperight', function( the_event ) {
			slideBigImage( true );
		} );
		
		function slideBigImage( right ) {
			right = ( true === right );
			
			var current = $( 'a[data-expanded="yes"]' ).parents( '.gallery-item' );
			var next = null;
			
			if ( null === current || 'undefined' === typeof current ) {
				return;
			}
			
			if ( right ) {
				next = current.next( '.gallery-item' );
				if ( null === next || 'undefined' === typeof next || 0 == next.length ) {
					next = current.parent().find( '.gallery-item:first' );
				}
			} else {
				next = current.prev( '.gallery-item' );
				if ( null === next || 'undefined' === typeof next || 0 == next.length ) {
					next = current.parent().find( '.gallery-item:last' );
				}
			}
			
			if ( null === next || 'undefined' === typeof next ) {
				return;
			}
			
			ajax_space.find( 'img' ).fadeOut( 'fast', function() {
				
				$( 'a[data-expanded="yes"]' ).removeAttr( 'data-expanded' );
				var new_src = next.find( 'a:first' ).attr( 'data-expanded', 'yes' ).attr( 'href' );
				$( this ).attr( 'src', new_src ).on( 'load', function() { $( this ).fadeIn( 'fast' ); } );
				
			} );
			
		}
		
		function closeBigImage() {
			ajax_space.fadeOut( 'fast' );
		}
		
		ajax_space.hide();
		
		$( '.gallery-item > a' ).click( function() {
			
			$( 'a[data-expanded="yes"]' ).removeAttr( 'data-expanded' );
			var expand = $( this ).attr( 'data-expanded', 'yes' );
			var href = expand.attr( 'href' );
			var the_img = $( '<img/>', { src: href, class: 'the-big-image' } );
			
			ajax_space.fadeOut( 'fast', function() {
				
				ajax_space.find( 'img' ).remove();
				ajax_space.append( the_img ).fadeIn( 'fast' );
				
			} );
			
			return false;
			
		} );
		
	} );

} )( jQuery );
